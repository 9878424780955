@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    height: full;    
    background-color: #202023;
}

html {
    scroll-behavior: smooth;
}